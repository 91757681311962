export default {
  title: 'Locatieplan - {municipality}',
  chooseMunicipality: 'Kies een gemeente om verder te gaan',
  continue: 'Ga verder',
  moreInfo: 'Meer informatie',
  chargingpoint: {
    status: {
      'unknown': 'Onbekend',
      'alert': 'Aandachtspunt',
      'charging-hub-definitive-private': 'Toekomstig laadplein op privaat terrein',
      'charging-hub-definitive-semipublic': 'Toekomstig laadplein op semi-publiek terrein',
      'charging-hub-definitive': 'Toekomstig laadplein',
      'charging-hub-disabled-private': 'Uitgeschakeld laadplein op privaat terrein',
      'charging-hub-disabled-semipublic': 'Uitgeschakeld laadplein op semi-publiek terrein',
      'charging-hub-disabled': 'Uitgeschakeld laadplein',
      'charging-hub-in-progress-private': 'Laadplein in voorbereiding op privaat terrein',
      'charging-hub-in-progress-proprietary': 'Bedrijfseigen laadplein in voorbereiding',
      'charging-hub-in-progress-semipublic': 'Laadplein in voorbereiding op semi-publiek terrein',
      'charging-hub-in-progress': 'Laadplein in voorbereiding',
      'charging-hub-realized-private': 'Bestaand laadplein op privaat terrein',
      'charging-hub-realized-proprietary': 'Bestaand bedrijfseigen laadplein',
      'charging-hub-realized-semipublic': 'Bestaand laadplein op semi-publiek terrein',
      'charging-hub-realized': 'Bestaand laadplein',
      'charging-hub-rejected-private': 'Afgekeurd laadplein op privaat terrein',
      'charging-hub-rejected-semipublic': 'Afgekeurd laadplein op semi-publiek terrein',
      'charging-hub-rejected': 'Afgekeurd laadplein',
      'charging-hub-request-private': 'Aangevraagd laadplein op privaat terrein',
      'charging-hub-request-semipublic': 'Aangevraagd laadplein op semi-publiek terrein',
      'charging-hub-request': 'Aangevraagd laadplein',
      'charging-hub-suggestion-private': 'Voorgesteld laadplein op privaat terrein',
      'charging-hub-suggestion-proprietary': 'Voorgesteld bedrijfseigen laadplein',
      'charging-hub-suggestion-semipublic': 'Voorgesteld laadplein op semi-publiek terrein',
      'charging-hub-suggestion': 'Voorgesteld laadplein',
      'definitive-private': 'Toekomstige laadpaal op privaat terrein',
      'definitive-semipublic': 'Toekomstige laadpaal op semi-publiek terrein',
      'definitive': 'Toekomstige laadpaal',
      'disabled-private': 'Uitgeschakelde laadpaal op privaat terrein',
      'disabled-semipublic': 'Uitgeschakelde laadpaal op semi-publiek terrein',
      'disabled': 'Uitgeschakelde laadpaal',
      'fastcharger-definitive-private': 'Toekomstige snellader op privaat terrein',
      'fastcharger-definitive-semipublic': 'Toekomstige snellader op semi-publiek terrein',
      'fastcharger-definitive': 'Toekomstige snellader',
      'fastcharger-disabled-private': 'Uitgeschakelde snellader op privaat terrein',
      'fastcharger-disabled-semipublic': 'Uitgeschakelde snellader op semi-publiek terrein',
      'fastcharger-disabled': 'Uitgeschakelde snellader',
      'fastcharger-in-progress-private': 'Snellader in voorbereiding op privaat terrein',
      'fastcharger-in-progress-semipublic': 'Snellader in voorbereiding op semi-publiek terrein',
      'fastcharger-in-progress': 'Snellader in voorbereiding',
      'fastcharger-realized-private': 'Bestaande snellader op privaat terrein',
      'fastcharger-realized-semipublic': 'Bestaande snellader op semi-publiek terrein',
      'fastcharger-realized': 'Bestaande snellader',
      'fastcharger-rejected-private': 'Afgekeurde snellader op privaat terrein',
      'fastcharger-rejected-semipublic': 'Afgekeurde snellader op semi-publiek terrein',
      'fastcharger-rejected': 'Afgekeurde snellader',
      'fastcharger-request-private': 'Aangevraagde snellader op privaat terrein',
      'fastcharger-request-semipublic': 'Aangevraagde snellader op semi-publiek terrein',
      'fastcharger-request': 'Aangevraagde snellader',
      'fastcharger-suggestion-private': 'Voorgestelde snellader op privaat terrein',
      'fastcharger-suggestion-semipublic': 'Voorgestelde snellader op semi-publiek terrein',
      'fastcharger-suggestion': 'Voorgestelde snellader',
      'in-progress-private': 'Laadpaal in voorbereiding op privaat terrein',
      'in-progress-semipublic': 'Laadpaal in voorbereiding op semi-publiek terrein',
      'in-progress': 'Laadpaal in voorbereiding',
      'realized-private': 'Bestaande laadpaal op privaat terrein',
      'realized-semipublic': 'Bestaande laadpaal op semi-publiek terrein',
      'realized': 'Bestaande laadpaal',
      'rejected-private': 'Afgekeurde laadpaal op privaat terrein',
      'rejected-semipublic': 'Afgekeurde laadpaal op semi-publiek terrein',
      'rejected': 'Afgekeurde laadpaal',
      'request-private': 'Aangevraagde laadpaal op privaat terrein',
      'request-semipublic': 'Aangevraagde laadpaal op semi-publiek terrein',
      'request': 'Aangevraagde laadpaal',
      'suggestion-private': 'Voorgestelde laadpaal op privaat terrein',
      'suggestion-semipublic': 'Voorgestelde laadpaal op semi-publiek terrein',
      'suggestion': 'Voorgestelde laadpaal',
      'participation-suggestion-private': 'Voorgestelde laadpaal op privaat terrein door inwoner',
      'participation-suggestion-semipublic': 'Voorgestelde laadpaal op semi-publiek terrein door inwoner',
      'participation-suggestion': 'Voorgestelde laadpaal door inwoner',
    },
  },
  modal: {
    title: {
      policy: 'Beleidsregels',
    },
  },
  map: {
    search: 'Zoek op adres',
    newLocation: 'Nieuwe locatie',
  },
  navbar: {
    title: 'Participatieportaal',
    titlePreview: 'Participatieportaal - Preview (opent op {openDate})',
    menuName: 'Menu',
    showPolicy: 'Toon beleidsregels',
    showIntroductionModal: 'Toon uitleg',
    downloadMap: 'Download kaart',
    viewer: 'Viewer',
    viewerTitlePreview: 'Viewer - Preview (opent op {openDate})',
  },
  actionSidebar: {
    chargingpointID: 'Locatie {id}',
    informationAboutLocation: 'Informatie over de locatie',
    leaveAMessage: 'Laat een reactie achter',
    proposeNewLocation: 'Stel een nieuwe locatie voor',
    hasCommented: 'U heeft al gereageerd op deze locatie.',
    disabledMessage: {
      realized: 'Deze laadpaal is al geïnstalleerd en daarom kan er niet op gereageerd worden.',
      inProgress: 'Deze laadpaal wordt binnenkort geïnstalleerd en daarom kan er niet op gereageerd worden.',
      noCpo: 'Op deze locatie kan niet gereageerd worden omdat er nog geen laadpaalexploitant aan is toegewezen.',
      general: 'Reacties op deze locatie zijn uitgeschakeld.',
      interactionOnPastParticipations: 'Reacties op deze locatie zijn uitgeschakeld, omdat deze locatie eerder is geparticipeerd.',
    },
    form: {
      feedbackPlaceholder: 'Plaats een opmerking',
      expiredCaptcha: 'De captcha puzzel is verlopen, herlaad de pagina en probeer opnieuw.',
      submitButton: {
        saving: 'Verzenden...',
        saveLocation: 'Stel deze locatie voor',
        locationSaved: 'De locatie is voorgesteld!',
        saveComment: 'Plaats opmerking',
        commentSaved: 'Opmerking is opgeslagen!',
      },
    },
  },
  locationPopUp: {
    noOutlets: 'Aantal laadpunten:',
  },
  suggestionButton: {
    startSuggesting: 'Stel een nieuwe locatie voor',
    stopSuggesting: 'Selecteer een locatie op de kaart',
  },
  legend: {
    allLayers: 'Alle laadpalen',
    openLegend: 'Open legenda',
    closeLegend: 'Sluit legenda',
  },
  downloadMap: {
    title: '{municipality} - Locatieplan openbare laadpalen',
  },
  closed: {
    title: 'De reactietijd is verlopen',
    message: 'U kunt niet meer reageren op het locatieplan.',
  },
  notFound: {
    title: 'Niet gevonden (404)',
    message: 'Helaas, deze pagina bestaat niet (meer).',
    municipalityNotFound: 'Deze gemeente heeft geen viewer of participatieportaal.',
    homeButton: 'Ga naar de thuis pagina',
  },
}
